/**
 * The ConfigCat flag keys as a tuple
 * !Must be an array of string literals, cannot invoke functions
 */
const CONFIGCAT_FLAG_KEYS = [
  'isBushelWalletButtonEnabled',
  'isAuthzPermissionsEnabled',
  'isTenantPermissionsPrefetchingEnabled',
  'isCustomerAuthzPermissionsEnabled',
  'isTeamAccessEnabled',
  'isRolesStaffFeatureEnabled',
] as const

export type ConfigCatFlagKey = (typeof CONFIGCAT_FLAG_KEYS)[number]

/**
 * The ConfigCat flag keys as an object for easy access
 */
export const CONFIGCAT_FLAGS = Object.fromEntries(
  CONFIGCAT_FLAG_KEYS.map((key) => [key, key])
) as Record<ConfigCatFlagKey, ConfigCatFlagKey>
