import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { memo, ReactNode } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { type FeatureName } from 'src/__fixtures__/featureConfig'
import {
  Box,
  Button,
  Grid,
  Heading,
  HStack,
  Spinner,
  Text,
  useBreakpoint,
  VStack,
} from 'src/components/designsystem'
import { DisplayConfig } from 'src/components/designsystem/display-config'
import {
  ErrorState,
  NoResultsState,
  ResourceListItemSkeleton,
  usePageHeightCheck,
  useStackedDetailModal,
} from 'src/components/resource'
import useAlternateFeatureName from 'src/data/use-alternate-feature-name'
import { labelToTestId } from 'src/utils/string/label-to-test-id'
import ListHeaderItem from './header/ListHeaderItem'
import type { HeadingProps } from '@chakra-ui/react'

interface ResourcePageProps<Item> {
  text: {
    title?: string
    feature?: string
    subHeading?: ReactNode
    emptyAction?: string | JSX.Element
    errorList: string
  }
  featureName?: FeatureName
  isFiltered: boolean
  onClearFilters?: () => void
  header?: ReactNode
  emptyFiltersStateHeader?: ReactNode
  summary?: ReactNode
  exportModal?: ReactNode
  paymentModal?: ReactNode
  detailModal?: ReactNode
  stackedModal?: ReactNode
  isExpandable?: boolean
  renderRowItem: (i: Item, index: number) => ReactNode
  columnConfig: DisplayConfig<Item>
  actionMenuConfig?: {
    key: string
    render: (item: any) => ReactNode
  }[]
  items: Item[]
  itemQuery:
    | UseInfiniteQueryResult<
        {
          data: Item[]
          meta: {
            pagination?: Pagination
            last_updated?: string
          }
        },
        unknown
      >
    | any
}

export default function ResourceListPage<Item>({
  text,
  header = null,
  emptyFiltersStateHeader = null,
  summary = null,
  exportModal = null,
  featureName,
  paymentModal = null,
  detailModal,
  stackedModal,
  isExpandable = true,
  renderRowItem,
  columnConfig,
  actionMenuConfig,
  itemQuery,
  items,
  isFiltered,
  onClearFilters,
}: ResourcePageProps<Item>) {
  const { hasError, hasResultsOrIsLoading, isEmptyState, isEmptyStateUnfiltered } = getListFacts({
    itemQuery,
    items,
    isFiltered,
  })

  const { currentItem } = useStackedDetailModal()
  const { featureName: pageTitle } = useAlternateFeatureName(featureName, text.title)

  return (
    <>
      {!isEmptyStateUnfiltered ? header : emptyFiltersStateHeader}

      {!isEmptyState && (
        <>
          {summary}
          {pageTitle && <PageHeadingTitle title={text.title}>{pageTitle}</PageHeadingTitle>}
          {text.subHeading}
        </>
      )}

      {hasResultsOrIsLoading && (
        <InfiniteList
          {...{
            text,
            columnConfig,
            renderRowItem,
            actionMenuConfig,
            itemQuery,
            items,
            isFiltered,
            isExpandable,
          }}
        />
      )}

      {isEmptyState && (
        <ListEmptyState
          {...{
            text,
            isFiltered,
            hasError,
          }}
          onClearFilters={onClearFilters}
        />
      )}

      {detailModal}
      {exportModal}
      {paymentModal}
      {currentItem && stackedModal}
    </>
  )
}

export function PageHeadingTitle({
  title,
  children,
  ...rest
}: Readonly<{ title?: string; children: ReactNode } & HeadingProps>) {
  return (
    <Heading
      type="h4"
      pt={[8, null, null, 12]}
      pb={[4, null, 6, 8]}
      pl={[4, null, null, 0]}
      data-testid={`${labelToTestId(title)}-title`}
      {...rest}
    >
      {children}
    </Heading>
  )
}

export function getListFacts<Item>({
  itemQuery,
  items,
  isFiltered,
}: Pick<ResourcePageProps<Item>, 'itemQuery' | 'items' | 'isFiltered'>) {
  const hasItems = items.length > 0
  const hasError = itemQuery.isError
  const hasResultsOrIsLoading = itemQuery.isLoading || hasItems
  const hasResultsWithError = hasItems && hasError
  const isEmptyState = !hasItems && !itemQuery.isLoading
  const isEmptyStateUnfiltered = isEmptyState && !isFiltered

  return {
    // hasItems,
    hasError,
    hasResultsOrIsLoading,
    hasResultsWithError,
    isEmptyState,
    isEmptyStateUnfiltered,
  }
}

const LIST_SKELETON_ROW_COUNT = 8

export type InfiniteListProps<Item> = Pick<
  ResourcePageProps<Item>,
  | 'text'
  | 'columnConfig'
  | 'renderRowItem'
  | 'actionMenuConfig'
  | 'itemQuery'
  | 'items'
  | 'isFiltered'
  | 'isExpandable'
>

export const InfiniteList = memo(function InfiniteList<Item>({
  text,
  columnConfig,
  renderRowItem,
  actionMenuConfig,
  itemQuery,
  items,
  isFiltered,
  isExpandable,
}: InfiniteListProps<Item>) {
  const { hasResultsWithError } = getListFacts({ itemQuery, items, isFiltered })
  const { itemListContainerRef } = usePageHeightCheck<Item>({ itemQuery, hasResultsWithError })

  return (
    <>
      <ListHeader columnConfig={columnConfig} isExpandable={isExpandable} />

      <InfiniteScroll
        style={{ overflow: 'visible' }}
        scrollableTarget="main"
        scrollThreshold={0.75}
        dataLength={items.length}
        hasMore={!hasResultsWithError && itemQuery.hasNextPage}
        next={() => itemQuery.fetchNextPage()}
        loader={
          <HStack justify="center" py={8}>
            <Box>
              <Spinner size="lg" />
            </Box>
          </HStack>
        }
      >
        {itemQuery.isLoading ? (
          <VStack w="100%" data-testid="list-skeleton">
            {[...(Array(LIST_SKELETON_ROW_COUNT).keys() as any)].map((i) => (
              <ResourceListItemSkeleton
                key={i}
                columnConfig={columnConfig}
                hasActionMenu={!!actionMenuConfig?.length}
              />
            ))}
          </VStack>
        ) : (
          <VStack w="100%" ref={itemListContainerRef}>
            {items.map(renderRowItem)}

            {hasResultsWithError && (
              <Text data-testid="list-error" py={8}>
                {text.errorList}
              </Text>
            )}
          </VStack>
        )}
      </InfiniteScroll>
    </>
  )
})

type ListHeaderProps<Item> = Pick<ResourcePageProps<Item>, 'columnConfig' | 'isExpandable'>

export function ListHeader<Item>({ columnConfig, isExpandable }: ListHeaderProps<Item>) {
  const { isDesktop } = useBreakpoint()

  if (!isDesktop) return null

  return (
    <Grid
      templateColumns={columnConfig.desktopTemplateColumns(columnConfig.items)}
      w="100%"
      display="grid"
      pr={6}
      pl={isExpandable ? '4.5rem' : '1.5rem'} // Spacing around toggle icon is on scale, but when we need to account for it here, it doesn't hit a scale value
      mb={4}
      textTransform="uppercase"
      textAlign="left"
      {...columnConfig?.headerProps}
    >
      {columnConfig.items.map(({ label, key, ...rest }, index) => (
        <ListHeaderItem key={label || key || index} label={label} {...rest} />
      ))}
    </Grid>
  )
}

type ListEmptyStateProps<Item> = Pick<
  ResourcePageProps<Item>,
  'text' | 'isFiltered' | 'onClearFilters'
> & { hasError: boolean }

export function ListEmptyState<Item>({
  text,
  isFiltered,
  hasError,
  onClearFilters,
}: ListEmptyStateProps<Item>) {
  if (hasError) return <ErrorState isVerticallyCentered />

  return (
    <NoResultsState
      feature={text.feature}
      isFiltered={isFiltered}
      action={
        isFiltered && (
          <Button
            variant="primary"
            onClick={() => {
              onClearFilters?.()
            }}
          >
            Clear Search
          </Button>
        )
      }
    />
  )
}
